<template>
  <div id="Extension">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="p-3">

      <div class="flex  flex-col sm:flex-row gap-2 items-center justify-center content-evenly my-4">


        <!-- <img  src="@/assets/syncmanager_preview.jpeg" alt="" class="rounded-3xl sm:w-1/5 w-2/4"> -->
        <img  src="@/assets/syncmanager_preview.jpeg" alt="" class="rounded-3xl w-1/5 sm:inline hidden">
        <img  src="@/assets/syncmanager_logo.png" alt="" class="w-28 sm:hidden inline">
        
        <div class="sm:w-1/3 w-full sm:text-left text-center">

          <h1 class="m-2 ">
            <span class="font-bold brand-color-dark"><span class="sm:block hidden">StadtbahnfahrerClub<br></span>Sync App</span>
          </h1>

          <p class="m-2">
            Die StadtbahnfahrerClub Sync App stellt die wichtigste Verbindung zwischen Deinen Dienstdaten und
            unserer Plattform her. Synchronisiere in nur 60 Sekunden Deine Dienste.
          </p>
          <p class="m-2">
            Die App ist kostenfrei für Android im Google Play Store und iOS bei Apple TestFlight erhältlich - <b>jetzt ausprobieren!</b>
          </p>

          <div class="flex flex-row items-center justify-center sm:justify-start">
          <a href="https://play.google.com/store/apps/details?id=club.stadtbahnfahrer.syncmanager" target="_newTab"><img class="w-48" src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"/></a>
          <!-- <a href="https://apps.apple.com/de/app/stadtbahnfahrerclub-sync/id1582725510" target="_newTab"><img class="w-40" src="@/assets/apple_appstore_black.svg"></a> -->
          <a href="https://testflight.apple.com/join/yg3b0CdG" target="_newTab"><img class="w-40" src="@/assets/apple_appstore_black.svg"></a>
        </div>

        </div>
        

      </div>

      <div class="text-gray-500 text-center">
        <p>Unsere eigentliche App für iOS & Android ist zum Start der Beta Phase noch nicht zum Testen bereit.</p>
        <p>Der SyncManager ist nur für ausgewählte Verkehrsbetriebe verfügbar. Du kannst die Verfügbarkeit innerhalb der App prüfen.</p>
        <p><a class="link" href="https://docs.google.com/forms/d/e/1FAIpQLSca0IyZZxAfmkb9v3urR6dxp1VvpSK6a7-sVfxHVPJq8COplA/viewform?usp=pp_url" target="_newTab">Hilf uns</a> dabei die App in Deinen Arbeitsalltag zu integrieren.</p>
      </div>
      
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

import { mapGetters } from "vuex";

export default {
  name: "SyncManager",
  components: {
    TheHeader,
    SiteNav,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>